

.react-datepicker,
.react-datepicker__day {
    color: #212529;
    font-weight: normal;
    font-family: 'Nunito Sans';
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #3490dc !important;
    color: white;
}

.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
    background-color: #f2f5f7;
    color: #9ea8b2;
    text-transform: uppercase;
    font-size: 12px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    font-size: 14px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}