/* column card & inputs */
.column-card .card-heading .options {
    text-align: right;
    font-size: 17px;
}

.column-card .card-heading .options > span {
    margin-left: 10px;
}

.column-card .card-content {
    padding: 0;
}

.column-card .card-content .card-content-item {
    margin-bottom: 20px;
    position: static;
}

.column-card .card-content .card-content-item .value {
    text-align: left;
}

.column-card .card-content .left-half {
    width: 50%;
    padding-right: 5px;
}

.column-card .card-content .right-half {
    width: 50%;
    padding-left: 5px;
}

.value {
    text-align: left;
    margin-bottom: 1px;
    font-size: 14px;
}

.value > span {
    font-weight: 700;
    font-size: 14px;
    color: #707070;
    word-break: break-word;
}

.column-card .input-group-text {
    font-size: 14px;
}

.column-card .value input,
form .value input {
    text-align: left;
    padding: 0 0 0 5px;
    font-size: 14px;
    line-height: 33px;
}